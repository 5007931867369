import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

//shared components
import { AppComponent } from "./app.component";
import { TopnavbarComponent } from "./components/topnavbar/topnavbar.component";
import { LayoutComponent } from "./components/layout/layout.component";
import { NavigationComponent } from "./components/navigation/navigation.component";

//shared modules
import { SharedModule } from "./shared/shared.module";

//routing
import { routing } from "./app-routing.module";
import { ChatbotComponent } from "./pages/chatbot/chatbot.component";

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    NavigationComponent,
    TopnavbarComponent,
    ChatbotComponent
  ],
  imports: [BrowserModule, BrowserAnimationsModule, SharedModule, routing],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
