import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { environment } from "src/environments/environment";
import { Http } from "@angular/http";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DataserviceService {
  detailFormType: string = "detail";
  ssnFormType: string = "detailbyssn";
  summaryFormType: string = "summary";

  FormDataObj940: any;
  FormDataObj941: any;
  getformUrl: string;
  getReportAvailablity = `${environment.loginApiUrl}` + `auth/`;
  constructor(private http: HttpClient) {}

  getReportResponse() {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "text/plain");
    return this.http
      .get(this.getReportAvailablity + `report-availability`, { headers })
      .pipe(map((res: Response) => res));
  }

  formdata940(params) {
    this.FormDataObj940 = params;
    return true;
  }
  formdata941(params) {
    this.FormDataObj941 = params;
    return true;
  }
  getforms(): Observable<any> {
    this.getformUrl = environment.loginApiUrl + "auth/referencedata";
    return this.http.get(this.getformUrl);
  }
  mapFilteredValues(data) {
    return data.map((item) => item.itemName) || [];
  }
  mapOrder(listValues, selectedValues) {
    let newArray = [];
    listValues = listValues.filter(
      (elem) =>
        !selectedValues.find(({ itemName }) => elem.itemName === itemName)
    );
    newArray = newArray.concat(selectedValues);
    newArray = newArray.concat(listValues);
    return newArray;
  }

  getMultiselectDropdownSettings(type = "") {
    let settings = {
      singleSelection: false,
      searchPlaceholderText: "Select",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      badgeShowLimit: 2,
      noDataLabel: "",
    };
    if (type == "nodata") {
      console.log(type);
      settings.noDataLabel = "No data found";
      return settings;
    } else if (type == "loading") {
      settings.noDataLabel = "Please wait while loading data...";
      return settings;
    } else {
      return settings;
    }
  }

  getReports() {
    return [
      { name: "Dashboard", icon: "fa fa-dashboard", routerLink: "dashboard" },
      // {name: 'Govt Forms', icon: 'fa fa-check-square-o', reports:[
      //   {name: "Form 940", routerLink: "./form940"},
      //   {name: "Form 941", routerLink: "./form941"},
      //   {name: "Form 1099", routerLink: "./form1099misc"},
      //   {name: "W-2", routerLink: "./formw2"},
      //   {name: "592 B", routerLink: "./form592b"},
      // ]},
      {
        name: "Internal Forms",
        icon: "fa fa-check-square-o",
        reports: [
          { name: "W-2 Federal Summary Report", routerLink: "./w2fedsummary" },
          { name: "W-2 State Summary Report", routerLink: "./w2statesummary" },
          {
            name: "Quarterly FUTA Wage Summary",
            routerLink: "./formquarterlyfutawagesummary",
          },
          { name: "Over/Under FICA", routerLink: "./overunderfica" },
          { name: "Over/Under SDI", routerLink: "./overundersdi" },
          {
            name: "Negative Wages/Taxes Report",
            routerLink: "./negativewages",
          },
          {
            name: "Loan-Out Withholding Report",
            routerLink: "./loanoutwithholding",
          },
          {
            name: "Federal Tax Liability",
            routerLink: "./federaltaxliability",
          },
          {
            name: "FUTA Tax Liability Actual",
            routerLink: "./futataxliabilityactual",
          },
          {
            name: "FUTA Tax Liability Billed",
            routerLink: "./futataxliabilitybilled",
          },
          {
            name: "Federal Tax Liability (Canada)",
            routerLink: "./federaltaxliabilitycanada",
          },
          {
            name: "Suta Tax Liability Actual",
            routerLink: "./sutataxliabilityactual",
          },
          {
            name: "Suta Tax Liability Billed",
            routerLink: "./sutataxliabilitybilled",
          },
          { name: "Local Tax Liability", routerLink: "./localtaxliability" },
          { name: "State Tax Liability", routerLink: "./statetaxliability" },
          { name: "Combined Tax Report", routerLink: "./combinedtaxreport" },
          { name: "Daily Federal Tax Report", routerLink: "./dailyfedtax" },
          { name: "Daily State Tax Report", routerLink: "./dailystatetax" },
          { name: "New Hire Report", routerLink: "./newhire" },
          { name: "Invoice Register", routerLink: "./invoice-register" },
        ],
      },
      {
        name: "Benefit Reports",
        icon: "fa fa-clone",
        reports: [
          {
            name: "Union Contribution Check Request",
            routerLink: "./unioncontributioncheckrequest",
          },
          {
            name: "Pension Contributions Due",
            routerLink: "./pensioncontributionsdue",
          },
          {
            name: "Union Contribution List",
            routerLink: "./unioncontributionlist",
          },
          {
            name: "Payroll Benefits Register",
            routerLink: "./payrollbenefitsregister",
          },
          {
            name: "Employee Payroll Benefits Register",
            routerLink: "./employeepayrollbenefitsregister",
          },
          { name: "MPIP Rate Group", routerLink: "./mpip" },
          { name: "MPIPHP Back-Up Report", routerLink: "./mpiphpbackupreport" },
          { name: "MPIP Rate Group (BiWeekly)", routerLink: "./mpiprategroupbiweekly" },
          { name: "MPI-RG Coversheet", routerLink: "./mpirgcoversheet" },
          { name: "PHBP Contributions", routerLink: "./phbpcontributions" },
          { name: "Generic Report", routerLink: "./genericreport" },
          {
            name: "Generic Report By Client",
            routerLink: "./genericreportByClient",
          },
          { name: "DGA - Commercial Salary", routerLink: "./dga" },
          { name: "DGA - Commercial Vacation", routerLink: "./dga-vacation" },
          { name: "DGA - Freelance Salary", routerLink: "./freelancesalary" },
          { name: "DGA - Freelance Vacation", routerLink: "./freelancedgavac" },
          {
            name: "Freelance - Freelance Vacation",
            routerLink: "./w2statesummary",
          },
          {
            name: "Freelance - Freelance Salary",
            routerLink: "./w2statesummary",
          },
          { name: "IANBF 1", routerLink: "./ianbf" },
          { name: "Local 476", routerLink: "./local476" },
          { name: "SAG AFTRA Final Cast Report", routerLink: "./sagaftra" },
          { name: "Phbp", routerLink: "./phbp" },
          { name: "Local Dues", routerLink: "./localdues" },
          { name: "Sag Residuals", routerLink: "./sagresiduals" },
          { name: "Local 817", routerLink: "./localhealth" },
          { name: "Local 829", routerLink: "./localpension" },
          { name: "NABET 700-M Remittance Report", routerLink: "./nabet700mremittance" },
        ],
      },
      {
        name: "Client Payroll Reports",
        icon: "fa fa-wrench",
        reports: [
          {
            name: "Employee Check Stub History",
            routerLink: "./employeecheckstubhistory",
          },
          {
            name: "Employee Pay History",
            routerLink: "./employeecheckstubdetailhistory",
          },
          {
            name: "Payroll Wage Register",
            routerLink: "./payrollwageregister",
          },
          { name: "Invoice Register", routerLink: "./invoice-register" },
          {
            name: "Custom Aggregate Fringe Report",
            routerLink: "./customaggregatefringerreport",
          },
          {
            name: "Custom Time Entry Report",
            routerLink: "./customtimeentryreport",
          },
          { name: "Fringe Recap", routerLink: "./fringerecap" },
          { name: "Account Detail", routerLink: "./accountdetails" },
          { name: "Detail Payroll Edit", routerLink: "./detailpayrolledit" },
          {
            name: "Account Detail By Job",
            routerLink: "./accountdetailsbyjob",
          },
          {
            name: "Shortfall By Deduction",
            routerLink: "./shortfallbydeduction",
          },
          {
            name: "PR Deduction Register By Employee",
            routerLink: "./prdedregisteremployee",
          },
          {
            name: "PR Deduction Register By Deduction",
            routerLink: "./prdedregisterbydeduction",
          },
          {
            name: "Shortfall by Employee and Deduction",
            routerLink: "./shortfallbyemployeededuction",
          },
          { name: "Employee History Y-T-D", routerLink: "./employeehistory" },
          { name: "Detail Pay Recap", routerLink: "./detailpayrecap" },
          {
            name: "Simple Check Register",
            routerLink: "./simplecheckregister",
          },
          { name: "SYTYCD", routerLink: "./sytycd" },
          { name: "Incentive Report", routerLink: "./incentivereport" },
          { name: "Incentive Report - Local Tax", routerLink: "./incentivereportlocaltax" },
          { name: "Incentive Report (State-Wise)", routerLink: "./incentive" },
          { name: "Incentive Report (State-Wise) - Local Tax", routerLink: "./incentivereportstatelocal" },
          { name: "Crew Address List", routerLink: "./crewaddresslist" },
          {
            name: "Union Affiliation Report ",
            routerLink: "./union-affiliation",
          },
          { name: "Detail Earnings Report", routerLink: "./detailearnings" },
          { name: "Summary Earnings Report", routerLink: "./summaryearnings" },
          { name: "WC Audit Report", routerLink: "./wcauditreport" },
          {
            name: "Holiday Accrual Report",
            routerLink: "./holidayaccrualreport",
          },
          {
            name: "Sick-Vacation Accrual Report",
            routerLink: "./sickvacationaccualreport",
          },
          {
            name: "Sick-Vacation Accrual Detail Report",
            routerLink: "./sickvacationaccualdetailreport",
          },
          {
            name: "Payroll Check Register",
            routerLink: "./payrollcheckregister",
          },
        ],
      },
      {
        name: "Gl Accounting",
        icon: "fa fa-money",
        reports: [
          { name: "AR Entry", routerLink: "./arentry" },
          { name: "Balance Sheet", routerLink: "./balance" },
          { name: "Client Terms Report", routerLink: "./clientterms" },
          { name: "Commissions Report", routerLink: "./commissions" },
          { name: "Funded Report", routerLink: "./funded" },
          { name: "GL Entry", routerLink: "./glentryreport" },
          { name: "GL Entry Recap", routerLink: "./glentryrecap" },
          {
            name: "GL Entry Recap Summary",
            routerLink: "./glentryrecapsummary",
          },
          {
            name: "Hours Worked By State",
            routerLink: "./hoursworkedbystatereport",
          },
          {
            name: "Payroll Check Register",
            routerLink: "./payrollcheckregisterreport",
          },
          {
            name: "Payroll Check Register Summary",
            routerLink: "./payrollcheckregisterreportsummary",
          },
          { name: "Positive Pay Report", routerLink: "./PositivePay" },
          { name: "Posted Payroll", routerLink: "./postedpayroll" },
          {
            name: "Profit and Lost Statement (P&L)",
            routerLink: "./profitlossstatement",
          },
          { name: "Rebate Report", routerLink: "./rebatereport" },
          { name: "WC Detail Report", routerLink: "./wcdetail" },
          { name: "WC Summary Report By Code", routerLink: "./wcsummary" },
        ],
      },
      {
        name: "Payroll Automation",
        icon: "fa fa-money",
        reports: [
          { name: "View All Interface", routerLink: "./viewallinterface" },
          { name: "Config Interface", routerLink: "./configinterface" },
        ],
      },

      {
        name: "Year End Reporting",
        icon: "fa fa-file-text-o",
        reports: [
          { name: "W2 Report", routerLink: "./w2report" },
          { name: "1099 Report", routerLink: "./report1099" },
          { name: "592B Report", routerLink: "./report592b" },
        ],
      },
      {
        name: "User Management",
        icon: "fa fa-user",
        routerLink: "./dashboard/usermanagement",
      },
      { name: "Rates", icon: "fa fa-percent", routerLink: "./dashboard/rates" },

      // {name: 'Online Services', icon:'fa fa-comments-o', routerLink: "./dashboard/onlineservices"},
    ];
  }
}
